<template>
  <!-- 
    画面: 特集リンク編集
    用途: 特集リンクを編集する
   -->
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">
              {{ $t("articleLinkEditPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>
            <span class="caption">
              {{ $t("common.editCaption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 特集タイトル -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("articleLinkCreatePage.title")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.title" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.title.$dirty && !$v.edit.title.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("articleLinkCreatePage.title"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.title.$dirty && !$v.edit.title.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("articleLinkCreatePage.title"),
                        vMax: $v.edit.title.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 特集タイトル -->

              <!-- #region 特集URL -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("articleLinkCreatePage.url")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.url" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.url.$dirty && !$v.edit.url.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("articleLinkCreatePage.url"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.url.$dirty && !$v.edit.url.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("articleLinkCreatePage.url"),
                        vMax: $v.edit.url.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 特集URL -->

              <!-- #region 宿リスト -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("articleLinkCreatePage.facilityList")
                  }}</strong></CCol>
                <CCol>
                  <CSelect
                    :options="facilityListList"
                    :value.sync="edit.facilityListId"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.facilityListId.$dirty &&
                      !$v.edit.facilityListId.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("articleLinkCreatePage.facilityList"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 宿リスト -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("common.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="edit.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("common.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <CButton
              color="secondary"
              class=""
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <SuccessModal
      :successModal="deleteSuccessModal"
      @close="deleteSuccessModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "articleLinkEdit",

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    articleLinkId() {
      return this.$route.params.articleLinkId;
    },
  },

  watch: {
    deleteSuccessModal(newValue, oldValue) {
      // 削除後のSuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region Edit
      /** 入力データ */
      // edit: {},
      edit: {
        id: 1,
        title: "titel01",
        url: "http://gotoyado.liberty-resort.net/",
        facilityListId: 2,
        facilityListName: "サンプル",
        description: "test",
        status: 200,
        displayOrder: 1,
        language: null,
      },
      //#endregion Edit

      //#region Options
      /** 宿リスト一覧 */
      facilityListList: [],

      //#endregion Options

      //#region Response data
      response: [],
      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        title: {
          required,
          maxLength: maxLength(255),
        },
        url: {
          required,
          maxLength: maxLength(1000),
        },
        facilityListId: {
          required,
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    onDeleteSubmit() {
      this.reqDelete();
    },

    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },

    onBackClicked() {
      this.backPage();
    },
    //#endregion Event

    //#region Request
    reqGet() {
      const callback = (data) => {
        this.edit = {
          id: data.id,
          title: data.title,
          url: data.url,
          facilityListId: data.facilityListId,
          facilityListName: data.facilityListName,
          description: data.description,
          status: data.status,
          displayOrder: data.displayOrder,
          language: data.language,
        };
        this.loading = false;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
        this.loading = false;
      };

      this.reqGetArticleLink(callback, errorCallback);
    },

    reqPut() {
      // note: 多言語対応時、editが複数設定される想定
      // const body = [this.edit];
      const body = this.edit;

      const callback = () => this.successModal = true;

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutArticleLink(body, callback, errorCallback);
    },

    reqDelete() {
      const callback = () => {
        this.deleteConfirmModal = false;
        this.deleteSuccessModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqDeleteArticleLink(callback, errorCallback);
    },

    /** 宿リスト一覧取得 */
    reqGetFacilityListList() {
      const callback = (a) => {
        this.pourTable(a.lists);
        this.loading = false;
      };

      this.reqGetHotelListAll(callback);
    },
    //#endregion Request

    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();

      datas.forEach((data) => {
        if (data.status == 100) {
          this.facilityListList.push({
            value: data.id,
            label: data.name,
          });
        } else {
          return null;
        }
      });

      // console.log(JSON.stringify(this.tableItems));
    },
    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.facilityListList = [];
    },

    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGetFacilityListList();
    this.reqGet();
  },
};
</script>